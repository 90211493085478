import React, { useContext, useEffect, useState } from 'react';
import { BaseContext } from '../../contexts/BaseProvider';
import { sanityClient } from '../../sanity';
import {
	Tranding_category,
} from '../../components/component';
import Meta from '../../components/Meta';
import { useDispatch, useSelector } from 'react-redux';
import { loadRaffleDataLive, loadRaffleDataFinished, updateProjectData, updateTrendingCategoryItemData, setFilterType } from '../../redux/counterSlice';
import Layout from '../../components/project/layout';

import { getRafflesBatch } from '../../libs/sanityHelper';

export default function Main({raffles, projectData}) {
	const { scrollRef } = useContext(BaseContext);

	const dispatch = useDispatch();

	useEffect(() => {
		window.scrollTo(0, scrollRef.current.scrollPos);
		const handleScrollPos = () => {
			scrollRef.current.scrollPos = window.scrollY;
		};
		window.addEventListener('scroll', handleScrollPos);
		return () => {
			window.removeEventListener('scroll', handleScrollPos);
		};
	});

	
	// useEffect(() => {
	// 	console.log("router.query.show", router.query.show)
	// 	if(router.query.show){
	// 		console.log("router.query.show", router.query.show)
	// 		dispatch(setFilterType(router.query.show))
	// 	}else{
	// 		console.log("router.query.show", "all")
	// 		dispatch(setFilterType('all'))
	// 	}
	// }, [router.query.show])
	

	
	useEffect(() => {
		if(raffles){
			const liveRaffles = raffles.filter(x => x.islive)
			const finishedRaffles = raffles.filter(x => !x.islive)
			console.log("raffles.length1", liveRaffles.length)
			console.log("raffles.length2", finishedRaffles.length)
			dispatch(loadRaffleDataLive(liveRaffles));
			dispatch(loadRaffleDataFinished(finishedRaffles));
		}
	}, [raffles, dispatch]);

	useEffect(() => {
		dispatch(updateProjectData(projectData))
	}, [projectData, dispatch]);

	return (
		<>
		<Layout>
			<main>
			<Meta title="Raffles || SolClub | One-Stop Token Utility app" />
			<Tranding_category/>
			</main>
			</Layout>
		</>
	);
}

export const getServerSideProps = async (pageContext) => {

	const todayTs = new Date().getTime()

	let pageSlug = pageContext.query.projectId
	const type = pageContext.query.type
	const filter = pageContext.query.filter

	const projectId = pageContext.query.projectId.split("_")[1]
// console.log(pageSlug)
	const projectquery = `*[ _type == "projects" && slug.current == $pageSlug][0]`
	const projectData = await sanityClient.fetch(projectquery, { pageSlug })

	const showLiveOnly = type === 'finished' ? false : true
	console.log("PAGE: [projectID]")


	const raffles = await getRafflesBatch(projectId,showLiveOnly)
console.log("Raffles", raffles.length)

	if (raffles.length > 0 && projectData) {
		return {
		  props: {
			raffles,
			projectData
		  },
		  }
	
		} else if(!raffles.length && projectData) {
		return {
		  props: {
			raffles: [],
			projectData: projectData,
		  },
		  }
		}else{
		return {
		redirect: {
		  permanent: false,
		  destination: "/404",
		}
	  }
	  }
  }
